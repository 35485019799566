<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div v-show="loading == true" class="alert alert-warning" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                </div><!-- /alert-danger -->
                <div v-show="leagues.length > 0">
                    <div class="btn-group w-100" role="group">
                        <button type="button" class="btn btn-secondary" @click="cat = '90'" :class="{'active': cat == '90'}" :disabled="cat == '90'">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">90 minutos</span>
                            <span v-else>90 min</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 'pt'" :class="{'active': cat == 'pt'}" :disabled="cat == 'pt'">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">Primeiro tempo</span>
                            <span v-else>1º tempo</span>
                        </button>
                        <button type="button" class="btn btn-secondary" @click="cat = 'st'" :class="{'active': cat == 'st'}" :disabled="cat == 'st'">
                            <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                            <span v-if="platform == 'desktop'">Segundo tempo</span>
                            <span v-else>2º tempo</span>
                        </button>
                    </div><!-- /btn-group -->
                    <div class="row ml-0 mt-3 mb-3 mr-0">
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="rulesLeague">
                                <option value="" selected>Selecione uma liga</option>
                                <option v-for="(item, index) in leagues" :key="index" :value="item.id">{{item.nome}}</option>
                            </select>
                        </div>
                        <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                            <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Buscar opção...">
                        </div>
                        <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                            <button class="btn btn-warning btn-block" @click="save"><i class="fas fa-save mr-2"></i>Salvar alterações</button>
                        </div><!-- /col -->
                    </div><!-- /row -->
                    <div class="mt-3 mb-3" v-show="markets.length > 0">
                        <div class="table-responsive">
                            <table class="table table-striped table-dark table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Opção</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Limite</th>
                                        <th scope="col">Bloquear</th>
                                        <th scope="col">Porcentagem</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filteredMarkets" :key="index">
                                        <td class="align-middle text-nowrap">{{item.label}}</td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredMarkets[index].type">
                                                <option value="1" :selected="item.type == 1">Reduzir</option>
                                                <option value="2" :selected="item.type == 2">Aumentar</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input class="form-control w-auto w-limit" type="text" v-model="filteredMarkets[index].limit" v-number-only @paste="noPaste($event)"> 
                                        </td>
                                        <td>
                                            <select class="form-control w-auto" v-model="filteredMarkets[index].active">
                                                <option value="true" :selected="item.active == true">Não</option>
                                                <option value="false" :selected="item.active == false">Sim</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input class="form-control w-auto" type="text" v-model="filteredMarkets[index].percent" v-number-only @paste="noPaste($event)"> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div><!-- /div -->
                    </div><!-- /mt-3 mb-3 -->
                </div><!-- /div -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 90,
            error: '',
            search: '',
            loading: false,
            isLoading: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            },
            id: '',
            leagues: [],
            markets: []
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        save() {
            
            const self = this;
            const regExp = /[a-zA-Z]/g;
            const filter = self.markets.some(item => item.limit.toString().trim().length == 0 || item.percent.toString().trim().length == 0 || regExp.test(item.limit.toString().trim()) || regExp.test(item.percent.toString().trim()));

            if (filter) {
                Swal.fire(
                    'Atenção',
                    'Preencha todos os campos, utilizando apenas números!',
                    'warning'
                );
            } 
            else 
            {

                self.isLoading = true;
                
                api.post('dashboard/rules-leagues', {
                    id: self.id,
                    markets: self.markets
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        rulesLeague(event) {

            const self = this;
            const id = event.target.value;

            if (id == '') {
                self.id = '';
                self.markets = [];
            } else {

                self.id = id;
                self.markets = [];
                self.isLoading = true;

                api.get(`dashboard/rules-leagues/${id}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.markets = response.data.markets;
                        break;
                        default:
                            self.error = response.data.message;
                    }
                }).catch((error) => {
                    try {
                        self.error = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.error = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        noPaste(event) {
            return event.preventDefault();
        }
    },
    computed: {
        filteredMarkets() {
            return this.markets.filter((item) => {
                return item.cat == this.cat && item.label.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    directives: {
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/\D/g,"").replace(/[0-9]{12}/," ").replace(/(\d{1})(\d{8})$/,"$1.$2").replace(/(\d{1})(\d{5})$/,"$1.$2").replace(/(\d{1})(\d{1,2})$/,"$1.$2").replace(',','.');
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/leagues').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.leagues = response.data.leagues.sort((a, b) => {
                        return a.nome.localeCompare(b.nome)
                    });
                break;
                case 'nothing_found':
                    self.error = 'Não encontramos nenhuma liga disponível!'
                break;
                default:
                    self.error = response.data.message;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.form-control.w-auto {
    width: auto!important;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
</style>